@import "../../global.scss";
.portfolioList {
    font-size: 14px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    overflow: hidden;
    width: auto;
    cursor: pointer;
    @include mobile {
        margin-right: 20px;
    }
    &.active {
        background-color: $mainColor;
        color: white;
        height: auto;
        text-align: center;
        overflow: hidden;
    }
}