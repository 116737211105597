.rect {
    stroke-dasharray: 1100;
    stroke-dashoffset: 1000;
    animation: movedash 2s linear forwards infinite;
}

@keyframes movedash {
    to {
        stroke-dashoffset: 0%;
    }
}