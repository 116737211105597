.app {
    height: 100vh;
    scroll-behavior: smooth;
    overflow: hidden;
    .sections {
        width: 100%;
        height: calc(100vh);
        background-color: red;
        transition: 2s;
        position: relative;
        top: 70px;
        scroll-snap-type: y mandatory;
        scrollbar-width: none; //firifox
        &::-webkit-scrollbar {
            display: none;
        }
        >* {
            width: 100vw;
            height: calc(100vh);
            scroll-snap-align: start;
        }
    }
}

.load {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#app {
    animation: all 3s linear 1;
}

@keyframes all {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}