@import "../../global.scss";
.portfolio {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        font-size: 50px;
        height: auto;
        margin-top: 5%;
        overflow: hidden;
        @include mobile {
            font-size: 20px;
        }
    }
    ul {
        margin: 10px;
        padding: 0;
        height: auto;
        list-style: none;
        display: flex;
        @include mobile {
            margin: 10px 0;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        @include mobile {
            width: 100%;
        }
        .item {
            overflow-y: hidden;
            width: 350px;
            height: 250px;
            border-radius: 20px;
            border: 1px solid rgb(240, 239, 239);
            margin: 10px 20px;
            background: rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(255, 255, 255);
            position: relative;
            transition: all .5s ease;
            cursor: pointer;
            @include mobile {
                width: 130px;
                height: 100px;
            }
            h3 {
                position: absolute;
                font-size: 25px;
                display: none;
            }
            button {
                bottom: 0;
                position: absolute;
                font-size: 25px;
                display: none;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }
            &:hover {
                background-color: $mainColor;
                h3 {
                    display: block;
                    text-align: center;
                }
                img {
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }
    }
}

ul {
    overflow: hidden;
}

.portfolioList {
    padding: 10px;
    font-size: 23px;
    margin: 20px;
    overflow: hidden;
}

.link {
    text-decoration: none;
    color: #fff;
}