@import "../../global.scss";
.menu {
    width: 50px;
    height: 100vh;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s;
    &.active {
        right: 0;
    }
    ul {
        background-color: $mainColor;
        margin: 0;
        padding: 0;
        padding: 20px;
        border-radius: 0 50px 50px 0;
        list-style: none;
        font-size: 30px;
        font-weight: 300;
        color: #fff;
        width: 60%;
        li {
            margin-bottom: 25px;
            a {
                color: inherit;
                text-decoration: none;
                color: #fff;
            }
            &:hover {
                font-weight: 500;
            }
        }
    }
}

.sideicon {
    font-size: 29px;
}