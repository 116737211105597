@import "../../global.scss";
.review {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
        justify-content: space-around;
    }
    a {
        text-decoration: none;
    }
    h1 {
        font-size: 50px;
        margin-top: 5%;
        @include mobile {
            font-size: 20px;
        }
    }
    .container {
        margin-top: -5%;
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile {
            flex-direction: column;
            height: 100%;
        }
        .card {
            width: 250px;
            height: 50%;
            border-radius: 10px;
            box-shadow: 0px 0px 15px -8px black;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;
            transition: all 1s ease;
            margin: 20px;
            @include mobile {
                height: 180px;
                margin: 10px 0;
            }
            &.featured {
                width: 300px;
                height: 75%;
                margin: 0 30px;
                @include mobile {
                    width: 250px;
                    height: 180px;
                    margin: 1px;
                }
            }
            &:hover {
                transform: scale(1.1);
            }
            .top {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                img {
                    &.left,
                    &.right {
                        height: 25px;
                    }
                    &.user {
                        height: 200px;
                        width: 100px;
                        margin: 0 30px;
                        object-fit: contain;
                        @include mobile {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
            .center {
                padding: 10px;
                border-radius: 10px;
                background-color: rgb(250, 244, 245);
                @include mobile {
                    font-size: 12px;
                    padding: 5px;
                }
            }
            .bottom {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                h3 {
                    margin-bottom: 5px;
                    @include mobile {
                        font-size: 14px;
                    }
                }
                h4 {
                    color: rgb(121, 115, 115);
                    font-size: 22px;
                    @include mobile {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.card:nth-child(4) .user {
    animation: react 5s linear infinite;
}

@keyframes react {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}