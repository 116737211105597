@import "../../global.scss";
.contact {
    background-color: white;
    display: flex;
    @include mobile {
        flex-direction: column;
    }
    .left {
        flex: 1;
        overflow: hidden;
        img {
            height: 100%;
        }
        @include mobile {
            display: none;
        }
    }
    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2 {
            font-size: 30px;
        }
        form {
            width: 70%;
            height: 55%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            input {
                width: 400px;
                height: 30px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                font-size: 18px;
                padding: 10px;
                border: none;
                border-radius: 10px;
                &:focus {
                    outline: none;
                }
                @include mobile {
                    width: 300px;
                    height: 40px;
                    margin-bottom: 10px;
                }
            }
            textarea {
                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                border: none;
                padding: 10px;
                width: 400px;
                height: 200px;
                font-size: 18px;
                letter-spacing: 1px;
                resize: none;
                &:focus {
                    outline: none;
                }
                @include mobile {
                    width: 300px;
                    height: 100px;
                }
            }
            button {
                width: 100px;
                padding: 5px;
                color: white;
                background-color: crimson;
                border: none;
                border-radius: 10px;
                font-size: 18px;
                font-weight: 500;
                cursor: pointer;
                &:focus {
                    outline: none;
                }
            }
            span {
                color: green;
            }
        }
    }
}