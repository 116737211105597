@import "../../global.scss";
.intro {
    background-color: white;
    display: flex;
    @include mobile {
        flex-direction: column;
        align-items: center;
    }
    .left {
        margin-top: 3%;
        flex: 0.5;
        overflow: hidden;
        .imgContainer {
            width: 700px;
            height: 700px;
            display: flex;
            align-items: center;
            justify-content: center;
            float: right;
            @include mobile {
                align-items: flex-start;
            }
            img {
                @include mobile {
                    height: 50%;
                }
            }
        }
    }
    .right {
        overflow-y: hidden;
        flex: 0.5;
        position: relative;
        .wrapper {
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            margin-top: 20%;
            @include mobile {
                padding-left: 0;
                align-items: center;
            }
            h1 {
                font-size: 60px;
                margin: 10px 0;
                @include mobile {
                    font-size: 40px;
                }
            }
            h2 {
                font-size: 35px;
            }
            h3 {
                font-size: 30px;
                @include mobile {
                    font-size: 20px;
                }
                span {
                    font-size: inherit;
                    color: blue
                }
                .ityped-cursor {
                    animation: blink 1s infinite;
                }
                @keyframes blink {
                    50% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            }
        }
        a {
            position: absolute;
            bottom: 10%;
            left: 40%;
            img {
                width: 50px;
                animation: arrowBlink 2s infinite;
            }
            @keyframes arrowBlink {
                100% {
                    opacity: 0;
                }
            }
        }
    }
}

.quote {
    top: 380%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    color: #8b8b8b;
    @include mobile {
        display: none;
    }
}

.star {
    color: red;
}

.infos {
    line-height: 60px;
    font-size: 23px;
    font-weight: 500;
    color: #8b8b8b;
}

// .reds {
//     color: red;
// }